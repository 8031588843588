import stateManager from '@common/services/stateManager';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {

};

const i18n = new VueI18n({
  locale: stateManager.get("language", "vn"),
  messages,
  fallbackLocale: 'vn'
});

export default i18n;
