export default {
    attrs: {
    },
    content: {
        divider: {
            attrs: {

            }
        },
        template: {
            item: {
                attrs: {

                }
            },
            disabledItem: {
                attrs: {
                }
            },
        }
    }
}
