export default {
    content: {
        addItemBtn: {
            attrs: {
                color: "primary",
                small: true
            },
            content: {
                icon: "mdi-plus",
                text: "Add"
            },
            on: {

            }
        },
        template: {
            itemKey: {
                attrs: {
                    label: "Key",
                    required: true,
                }
            },
            itemValue: {
                attrs: {
                    label: "Value",
                    required: true,
                }
            },
        }
    }
}
