export default {
  attrs: {
    "max-width": "600px"
  },
  content: {
    title: {
      attrs: {
        class: "headline"
      },
      html: "Notify"
    },
    content: {
      attrs: {}
    },
    buttons: {
      close: {
        attrs: {
          color: "green darken-1",
          text: true,
        },
        content: {
          text: "OK"
        }
      },
    },
  }
}
