class StateManager {
    private cachedData = {}

    public get(key: string, defaultValue: any = null, decorator: any = null) {
        let value = this.cachedData[key]
        if (value !== undefined) {
            return value
        }
        value = localStorage.getItem(key)
        if (value === undefined || value === null) {
            this.cachedData[key] = defaultValue
            return defaultValue
        }
        let parseValue = JSON.parse(value)
        if (decorator) {
            parseValue = decorator(parseValue)
        }
        this.cachedData[key] = parseValue
        return parseValue
    }

    public set(key: string, value: any, persistentDisabled = false) {
        this.cachedData[key] = value
        if (!persistentDisabled) {
            localStorage.setItem(key, JSON.stringify(value))
        }
    }

    public delete(key: string) {
        delete this.cachedData[key]
        localStorage.removeItem(key)
    }
}

export default new StateManager()
