export default {
  attrs: {
    width: "500px"
  },
  content: {
    title: {
      attrs: {
        dense: true,
        flat: true,
        tile: true,
        dark: true,
        color: "primary",
        class: 'justify-center',
      },
      html: "CONFIRM"
    },
    content: {
      attrs: {
        class: "text-center",
      }
    },
    buttons: {
      yes: {
        attrs: {
          color: "primary",
        },
        content: {
          text: "Yes",
        }
      },
      no: {
        content: {
          text: "No"
        },
        on: {
          xClick() {
            // console.log("no")
          }
        }
      }
    },
    template: {
      button: {
        attrs: {
          xClass: "ml-1 mr-1",
          text: true,
        }
      },
    }
  }
}
