export default class {
    private _value: any;
    private _updateCallback: any;

    constructor(_value?: any, _updateCallback?: any, immediately = true) {
        this._value = _value
        this._updateCallback = _updateCallback
        if (immediately && this._updateCallback) {
            this._updateCallback(this._value)
        }
    }

    public onUpdated(_updateCallback: any, immediately: boolean) {
        this._updateCallback = _updateCallback
        if (immediately && this._updateCallback) {
            this._updateCallback(this._value)
        }
    }

    get value() {
        return this._value
    }

    set value(_value) {
        const changed = this._value !== _value
        this._value = _value
        if (changed && this._updateCallback) {
            this._updateCallback(_value)
        }
    }
}
