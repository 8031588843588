import merge from "deepmerge";
import { isPlainObject } from "is-plain-object"


export default {
    clone(x, y = {}, options: any = {}) {
        options.isMergeableObject = isPlainObject
        return merge(x, y, options)
    },
};
