import Vue from "vue";
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import merge from "../modules/vuetifyx/utils/merge";
import configProvider from "../services/configProvider";
import '@mdi/font/css/materialdesignicons.css'


Vue.use(Vuetify);
export default new Vuetify(merge.clone({
    icons: {
        iconfont: 'mdi',
    },
}, configProvider.get("VUETIFY_OPTIONS", {})));
