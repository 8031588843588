export default {
  attrs: {
    scrollable: true,
    width: "750px"
  },
  content: {
    autofocus: true,
    card: {
      attrs: {}
    },
    toolbar: {
      attrs: {
        dense: true,
        flat: true,
        tile: true,
        dark: true,
        color: "primary"
      }
    },
    content: {
      makeAttrs(attrs: any = {}, dialog) {
        attrs.xContext = dialog
        return attrs
      }
    },
    cancelDisabled: false,
    buttons: {
      cancel: {
        attrs: {
          color: ""
        },
        content: {
          text: "Cancel",
        },
        on: {
          xClick({ self }) {
            const dialog = self.context()
            dialog.hide()
          }
        }
      }
    },
    template: {
      button: {
        attrs: {
          xClass: "ml-1 mr-1",
          text: true,
          color: "primary"
        },
      },
    }
  }
}
