import jwtDecode from "jwt-decode";
import stateManager from './stateManager'
import { v4 as uuidv4 } from 'uuid';

export class UserManager {
    public getDeviceId(): string {
        let value = stateManager.get("deviceId")
        if (!value) {
            value = uuidv4()
            stateManager.set("deviceId", value)
        }
        return value
    }

    public getUserInfo() {
        return stateManager.get("userInfo")
    }

    public setAccessToken(accessToken: string) {
        const payload: any = jwtDecode(accessToken)
        stateManager.set("accessToken", accessToken)
        stateManager.set("userInfo", payload.data)
    }

    public getAccessToken() {
        return stateManager.get("accessToken")
    }

    public deleteAccessToken() {
        stateManager.delete("accessToken")
        stateManager.delete("userInfo")
    }

    public setAccessTokenForScenario(scenario: string, accessToken: string) {
        if (!scenario) {
            this.setAccessToken(accessToken)
        } else {
            stateManager.set(`accessTokenByScenario_${scenario}`, accessToken)
        }
    }

    public getAccessTokenForScenario(scenario: string) {
        if (!scenario) {
            return this.getAccessToken()
        } else {
            return stateManager.get(`accessTokenByScenario_${scenario}`)
        }
    }

    public deleteAccessTokenForScenario(scenario: string) {
        if (!scenario) {
            return this.deleteAccessToken()
        } else {
            return stateManager.delete(`accessTokenByScenario_${scenario}`)
        }
    }

    public roles() {
        const userInfo = this.getUserInfo()
        return userInfo && userInfo.roles || []
    }

    public checkRole(roles, ignoreSuperAdminRole = false) {
        if (!(roles instanceof Array)) {
            roles = [roles]
        }
        for (const roleItem of this.roles()) {
            if (!ignoreSuperAdminRole && roleItem === this.superRole()) {
                return true
            }
            for (const role of roles) {
                if (role === roleItem) {
                    return true
                }
            }
        }
        return false
    }

    public ifRole(roles, item, ignoreSuperAdminRole = false) {
        if (this.checkRole(roles, ignoreSuperAdminRole)) {
            return item
        }
        return undefined
    }

    public ifRoleMap(roleValues, ignoreSuperAdminRole = false) {
        for (const item of roleValues) {
            if (this.checkRole(item.roles, ignoreSuperAdminRole)) {
                return item.value
            }
        }
        return undefined
    }

    protected superRole(): string {
        return "superAdmin"
    }
}
