export default {
  attrs: {},
  content: {
    searches: {
      searchAll: {
        attrs: {
          placeholder: "Search",
          autocomplete: "off"
        }
      },
      advancedSearch: {}
    },
    searchButton: {
      attrs: {
        xClass: "px-4 mb-2"
      },
      content: {
        text: "Search",
        iconAttrs: {
        }
      }
    }
  },
  ext: {
    hidden: false
  }
}
