import Vue from 'vue'
import Layout from './views/Layout.vue'
import router from './plugins/router'
import store from './plugins/store'
import i18n from './plugins/i18n';
import './plugins/vuetifyx';
import "./assets/css/style.scss"
import vuetify from '@common/plugins/vuetify';
import userManager from './services/userManager';

Vue.config.productionTip = false

Vue.filter("rdCount", (value) => {
  if (!userManager.checkRole(["rd", "rdl"], true)) {
    return value
  }
  if (value > 200) {
    return "200+"
  }
  return value
})

Vue.filter("rdPcs", (value) => {
  if (!userManager.checkRole(["rd", "rdl"], true)) {
    return value
  }
  return ""
})

Vue.filter("rdRevenue", (value) => {
  if (!userManager.checkRole(["rd", "rdl"], true)) {
    return value
  }
  return ""
})

Vue.filter("rdIncome", (value) => {
  if (!userManager.checkRole(["rd", "rdl"], true)) {
    return value
  }
  return ""
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(Layout)
}).$mount('#app')
