import merge from "./merge";
import _ from "lodash";

function get(data, propertyKey) {
  if (!data || typeof data !== "object") {
    return undefined
  }
  return data[propertyKey]
}

export default {
  get(data, propertyChainStr, defaultValue?: any) {
    const propertyKeys = propertyChainStr.split(".")
    let currentValue = data
    for (const i in propertyKeys) {
      const propertyKey = propertyKeys[i]
      currentValue = get(currentValue, propertyKey)
      if (currentValue === undefined) {
        return defaultValue
      }
    }
    return currentValue
  },
  set(formData) {
    let newObj = {}
    const objMultiLevel = {}
    for (const propertyKey in formData) {
      const propertyKeys = propertyKey.split(".")
      if (propertyKeys.length > 1) {
        objMultiLevel[propertyKey] = formData[propertyKey]
      } else {
        newObj[propertyKey] = formData[propertyKey]
      }
    }
    for (const key in objMultiLevel) {
      let object = {}
      object = _.set(object, key, objMultiLevel[key])
      newObj = merge.clone(newObj, object)
    }
    return newObj
  }
}
