export default {
    attrs: {
        "offset-y": true,
        left: true,
    },
    content: {
        autofocus: false,
        cardContent: {
            card: {
                attrs: {}
            },
            cancelDisabled: false,
            buttons: {
                cancel: {
                    attrs: {
                        color: ""
                    },
                    content: {
                        text: "Cancel",
                    },
                    on: {
                        xClick({ self }) {
                            const menu = self.context()
                            menu.hide()
                        }
                    }
                }
            },
            template: {
                button: {
                    attrs: {
                        xClass: "ml-1 mr-1",
                        text: true,
                        color: "primary"
                    },
                },
                topButton: {
                    type: "text"
                }
            }
        }
    }
}
