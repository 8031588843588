export default {
  attrs: {},
  content: {
    content: {
      html: ""
    },
    button: {},
    template: {
      button: {
        attrs: {
          text: true,
        },
        content: {
          text: "Close"
        },
        on: {
          xClick({self}) {
            self.context().model.value = false
          }
        }
      }
    }
  }
}
