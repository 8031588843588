import arrayInput from "./arrayInput";
import autocomplete from "./autocomplete";
import breadcrumbs from "./breadcrumbs";
import btn from "./btn";
import confirmDialog from "./confirmDialog";
import dataTable from './dataTable';
import dateTimePicker from './dateTimePicker';
import defaultDataTable from './defaultDataTable';
import dialog from "./dialog";
import display from "./display";
import form from './form';
import input from './input';
import keyValuesInput from "./keyValuesInput";
import menu from "./menu";
import notifyDialog from "./notifyDialog";
import notifySnackBar from './notifySnackBar';
import scrollableTabs from "./scrollableTabs"
import search from "./search";
import tabBasedForm from "./tabBasedForm";
import timePicker from "./timePicker";

export default {
  arrayInput,
  autocomplete,
  breadcrumbs,
  btn,
  confirmDialog,
  dataTable,
  dateTimePicker,
  defaultDataTable,
  dialog,
  display,
  form,
  input,
  keyValuesInput,
  menu,
  notifyDialog,
  notifySnackBar,
  scrollableTabs,
  search,
  tabBasedForm,
  timePicker,
}
