export default {
  attrs: {
  },
  content: {
    preIconAttrs: {
      class: "mr-1"
    },
    iconAttrs: {
      class: "ml-1"
    },
  },
  target: {
    tooltip: {
      attrs: {
        top: true,
      }
    }
  }
}
