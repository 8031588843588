export default {
  attrs: {
    hideLabel: true,
    labelAttrs: {},
  },
  typeMappings: {
    text: {
      type: "v-text-field",
      attrs: {
      }
    },
    textMultiple: {
      type: "v-combobox",
      attrs: {
        multiple: true,
        "small-chips": true,
      }
    },
    password: {
      type: "v-text-field",
      attrs: {
        type: "password",
        autocomplete: "new-password"
      }
    },
    email: {
      type: "v-text-field",
      attrs: {
        type: "email",
      },
      content: {
        rules: {
          email: true
        }
      }
    },
    phone: {
      type: "v-text-field",
      attrs: {
        type: "tel",
      },
    },
    number: {
      type: "v-text-field",
      attrs: {
        type: "number"
      },
      content: {
        filter(value) {
          return value && parseFloat(value)
        }
      },
    },
    integer: {
      type: "v-text-field",
      attrs: {
        type: "number"
      },
      content: {
        filter(value) {
          return value && parseInt(value, 10)
        }
      },
    },
    textLarge: {
      type: "v-textarea",
      attrs: {
        rows: 3,
      }
    },
    range: {
      type: "v-slider",
      attrs: {}
    },
    boolean: {
      type: "v-checkbox",
      attrs: {
        "true-value": true,
        "false-value": false,
      }
    },
    booleanAlt: {
      type: "v-checkbox",
      attrs: {}
    },
    select: {
      type: "v-select",
      attrs: {}
    },
    selectMultiple: {
      type: "v-select",
      attrs: {
        multiple: true,
        chips: true,
        dense: true,
      }
    },
    comboBoxMultiple: {
      type: "v-combobox",
      attrs: {
        multiple: true,
        chips: true,
        dense: true,
      }
    },
    file: {
      type: "v-file-input",
      attrs: {}
    },
    color: {
      type: "v-color-picker",
      attrs: {
        "hide-mode-switch": true,
        mode: "hexa",
        hideLabel: false,
      },
      ext: {
        defaultValue: "#000000"
      }
    },
  }
}
