import merge from "../modules/vuetifyx/utils/merge";

declare global {
    interface Window {
        vueConfigProvider: any;
    }
}

export default {
    get(value: string, defaultValue?: any) {
        const vueConfigProvider = window.vueConfigProvider
        return vueConfigProvider[value] || defaultValue
    },
    set(key: string, value: any) {
        window.vueConfigProvider = merge.clone(window.vueConfigProvider, { [key]: value })
    },
}
