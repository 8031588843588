export default {
    content: {
        menuAttrs: {

        },
        dpAttrs: {

        },
        textFieldAttrs: {

        },
        format: "YYYY-MM-DD"
    }
}
