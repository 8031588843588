



















import defaultComponent from "../../utils/defaultComponent";
import merge from "../../utils/merge";

export default defaultComponent.apply({
  data() {
    return {
      displayFields: {},
      displaySetting: {},
      displayFieldValues: {},
      version: 1,
    };
  },
  methods: {
    initialize() {
      this.reset();
    },
    reset(displaySetting) {
      if (!displaySetting) {
        displaySetting = this.options.content.displaySetting;
      }
      this.displayFieldValues = {};
      this.displayFields = merge.clone({}, this.options.content.displayFields);
      this.displaySetting = merge.clone(
        {
          displayFields: {},
        },
        displaySetting
      );

      for (const fieldName in this.displayFields) {
        const field = this.displayFields[fieldName];
        if (!field) {
          continue;
        }
        if (this.displaySetting.displayFields[fieldName] !== undefined) {
          this.displayFieldValues[fieldName] = this.displaySetting.displayFields[fieldName];
        } else {
          this.displayFieldValues[fieldName] = !field.hidden;
        }
      }
      this.version++;
    },
    notifyUpdate() {
      this.emit("changed", this.calculate());
    },
    calculate() {
      const displayFields = {};
      for (const fieldName in this.displayFields) {
        const field = this.displayFields[fieldName];
        if (!field) {
          continue;
        }
        displayFields[fieldName] = this.displayFieldValues[fieldName];
      }
      return { displayFields };
    },
  },
  extraOptions: {
    defaultClass: "fieldSelectForm",
  },
});
