export default {
    content: {
        menuAttrs: {

        },
        dpAttrs: {

        },
        tpAttrs: {
            "use-seconds": true
        },
        textFieldAttrs: {

        },
        format: "YYYY-MM-DD",
        timeFormat: "hh:mm:ss"
    }
}
