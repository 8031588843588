import { ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full';

export default {
  install(Vue) {
    Vue.component('ValidationProvider', ValidationProvider);
    extend("custom", async (value, data) => {
      const result = await data[0](value)
      return result
    }, {
      paramNames: ["callback"]
    })
  }
}
