import DataContainer from "../../common/DataContainer";
import _ from "lodash";

export default {
  multiSelectActionButtons: {
    delete: {
      content: {
        icon: "mdi-delete"
      },
      action: {
        name: "Delete",
        confirmMessage(dataTable) {
          return `Do you want to delete this/these <strong>${dataTable.model.value.length} item(s)? </strong><br/>This cannot be undone!`;
        },
        deselect: true
      },
      template: {
        targets: {
          confirm: {
            content: {
              title: {
                html: "CONFIRM DELETE"
              },
            },
          }
        }
      }
    },
  },
  topActionButtons: {
    insert: {
      attrs: {
      },
      content: {
        text: "Add new",
        "icon": "mdi-plus-box",
        iconAttrs: {
          class: "ml-1"
        }
      },
      target: {
        dialog: {
          handlers: {
            initialize() {
              this.options.content.buttons.save.states.enabled = new DataContainer(false)
            }
          },
          attrs: {
            persistent: true,
          },
          content: {
            toolbar: {
              title: "Add New"
            },
            content: {
              type: "XForm",
              attrs: {
                xOptions: {
                  on: {
                    xSubmit({ self }) {
                      self.context().dialog.getButton("save").emit("xClick")
                    }
                  }
                }
              },
              makeAttrs(attrs: any = {}, dialog) {
                attrs.xModel = dialog.options.content.buttons.save.states.enabled
                attrs.xContext = dialog
                return attrs
              }
            },
            buttons: {
              back: {
                on: {
                  xClick({ self }) {
                    const dialog = self.context();
                    const form = dialog.contentContainer.value;
                    const scrollableTabs = form.container.value;
                    scrollableTabs.back();
                  },
                },
                ext: {
                  hidden: true,
                },
              },
              cancel: {
                on: {
                  xClick({ self }) {
                    const dialog = self.context()
                    const form = dialog.contentContainer.value
                    form.reset()
                    dialog.hide()
                  }
                }
              },
              next: {
                attrs: {
                },
                content: {
                  text: "Next",
                },

                on: {
                  xClick({ self }) {
                    const dialog = self.context();
                    const form = dialog.contentContainer.value;
                    const scrollableTabs = form.container.value;
                    scrollableTabs.next();
                  },
                },
                ext: {
                  hidden: true,
                  condition(self) {
                    const form = self.contentContainer.value;
                    const scrollableTabs = form.container.value;
                    if (!scrollableTabs) {
                      return true;
                    }
                    return !scrollableTabs.isLastTab;
                  },
                }
              },
              save: {
                attrs: {
                },
                content: {
                  text: "Save"
                },
                states: {},
                on: {
                  async xClick({ self }) {
                    const dialog = self.context()
                    const btn = dialog.context()
                    const dataTable = btn.context()
                    const form = dialog.contentContainer.value;
                    const item = form.getData();
                    self.loading.value = true
                    if (await dataTable.execute(".insert", [item], {
                      disableLoading: true,
                      willRefresh: true,
                      successMessage: btn.options.ext && btn.options.ext.successMessage
                    })) {
                      form.reset()
                      dialog.hide()
                    }
                    self.loading.value = false
                  }
                }
              }
            }
          }
        }
      },
      ext: {
        successMessage() {
          return "Item has been added successfully"
        },
      }
    },
  },
  itemActionButtons: {
    edit: {
      content: {
        icon: "mdi-pencil"
      },
      target: {
        tooltip: { content: { text: "Edit" } },
        dialog: {
          handlers: {
            initialize() {
              this.options.content.buttons.save.states.enabled = new DataContainer(false)
            }
          },
          attrs: {
            persistent: true,
          },
          content: {
            toolbar: {
              title: "Edit",
              subTitle(self) {
                const btn = self.context()
                const { item } = btn.context()
                if (self.options.ext && self.options.ext.subTitleMaker) {
                  const subTitleMaker = self.options.ext.subTitleMaker
                  if (typeof subTitleMaker === "function") {
                    return subTitleMaker(item, self)
                  }
                  return _.get(item, subTitleMaker)
                }
              }
            },
            content: {
              type: "XForm",
              attrs: {
                xOptions: {
                  on: {
                    xSubmit({ self }) {
                      const dialog = self.meta().dialog
                      const saveButton = dialog.getButton("save")
                      saveButton.emit("xClick")
                    }
                  }
                }
              },
              makeAttrs(attrs: any = {}, dialog) {
                const btn = dialog.context()
                attrs.xData = new DataContainer(btn.context().item)
                attrs.xModel = dialog.options.content.buttons.save.states.enabled
                attrs.xContext = dialog
                return attrs
              }
            },
            buttons: {
              cancel: {
                on: {
                  xClick({ self }) {
                    const dialog = self.context()
                    const form = dialog.contentContainer.value
                    form.reset()
                    dialog.hide()
                  }
                }
              },
              back: {
                on: {
                  xClick({ self }) {
                    const dialog = self.context();
                    const form = dialog.contentContainer.value;
                    const scrollableTabs = form.container.value;
                    scrollableTabs.back();
                  },
                },
                ext: {
                  hidden: true
                },
              },
              save: {
                attrs: {
                },
                content: {
                  text: "Save"
                },
                states: {},
                on: {
                  async xClick({ self }) {
                    const dialog = self.context();
                    const form = dialog.contentContainer.value;
                    const btn = dialog.context();
                    const dataTable = btn.context().dataTable;
                    const item = form.getData();
                    self.loading.value = true
                    if (await dataTable.execute(".update", [item], {
                      willRefresh: true,
                      successMessage: btn.options.ext && btn.options.ext.successMessage
                    })) {
                      form.reset()
                      dialog.hide()
                    }
                    self.loading.value = false
                  }
                }
              },
              next: {
                attrs: {
                },
                content: {
                  text: "Next",
                },
                on: {
                  xClick({ self }) {
                    const dialog = self.context();
                    const form = dialog.contentContainer.value;
                    const scrollableTabs = form.container.value;
                    scrollableTabs.next();
                  },
                },
                ext: {
                  hidden: true,
                  condition(self) {
                    const form = self.contentContainer.value;
                    const scrollableTabs = form.container.value;
                    if (!scrollableTabs) {
                      return true;
                    }
                    return !scrollableTabs.isLastTab;
                  },
                },
              },
            }
          }
        }
      },
      ext: {
        successMessage() {
          return "Item has been saved successfully"
        },
      }
    },
    delete: {
      content: {
        icon: "mdi-delete",
      },
      target: {
        tooltip: { content: { text: "Delete" } },
        confirm: {
          content: {
            title: {
              html: "Confirm delete"
            },
            content: {
              html: (dialog) => {
                const btn = dialog.context();
                const { item } = btn.context();
                const label =
                  item[btn.options.ext.confirmDisplayField];
                return btn.options.ext.confirmMessage(item, label)
              },
            },
          },
          on: {
            async xYes({ self }) {
              self.hide()
              const btn = self.context();
              const { dataTable, item } = btn.context()
              await dataTable.execute(".delete", [item, dataTable], {
                willRefresh: true,
                successMessage: btn.options.ext && btn.options.ext.successMessage
              })
            }
          },
        }
      },
      ext: {
        confirmMessage(_, label) {
          return `Are you sure to delete item <b>"${label}"?</b><br/>This cannot be undone!`;
        },
        successMessage() {
          return "Item has been deleted successfully"
        }
      }
    }
  },
  template: {
    multiSelectActionButton: {
      action: {
        confirmMessage(dataTable, action) {
          return `Are you sure to apply action <b>${action.name}</b> to ${dataTable.model.value.length} item(s)<br/>This cannot be undone!`;
        },
      },
      template: {
        targets: {
          confirm: {
            content: {
              title: {
                html: "CONFIRM"
              },
              content: {
                html: dialog => {
                  const button = dialog.context();
                  const dataTable = button.context();
                  const message = dialog.values.action.confirmMessage(dataTable, dialog.values.action);
                  return message;
                }
              },
              buttons: {
                yes: {
                  content: {
                    text: "Delete",
                  }
                },
                no: {
                  content: {
                    text: "No"
                  }
                }
              },
            },
            on: {
              async xYes({ self }) {
                self.hide()
                const dataTable = self.context().context()
                const action = self.values.action
                if (await dataTable.execute(".executeActionOnMultiItems", [action.key, dataTable.model.value], {
                  willRefresh: true,
                  disableLoading: true,
                  enableProcessing: true,
                })) {
                  if (action.deselect) {
                    dataTable.model.value = []
                  }
                }
              }
            }
          }
        }
      },
    }
  }
}
