export default {
    content: {
        addItemBtn: {
            attrs: {
                color: "primary",
                small: true
            },
            content: {
                icon: "mdi-plus",
                text: "Add"
            },
            on: {

            }
        },
        template: {
            formOptions: {
                type: "x-empty",
                content: {
                    colLength: 6
                }
            }
        },
    }
}
