import Vue from "vue";
import merge from "./merge";
import propertyGetter from "./propertyGetter";
import DataContainer from '../common/DataContainer';


function extendProps(arr: any = []) {
  return ["xOptions", "xType", "xDefaultClass", "xContext", "xDisableForwardEvent", "xContainer", "xClass", "xMeta"].concat(arr)
}

function extendData(data, extraOptions: any = {}) {
  const self = this
  let newData: any = {}
  if (data) {
    newData = merge.clone(data.call(this))
  }
  newData.self = self
  newData.options = {}
  newData.values = {}

  if (extraOptions.forwardStates) {
    for (const stateName in extraOptions.forwardStates) {
      const forwardStateOpt = extraOptions.forwardStates[stateName]
      const forwardStateName = forwardStateOpt[0]
      const forwardStateDefaultValue = forwardStateOpt[1]
      const forwardStateAllowEmpty = forwardStateOpt[2]
      if (this[stateName] !== undefined) {
        newData[forwardStateName] = this[stateName]
      } else if (this.xOptions && this.xOptions.states && this.xOptions.states[forwardStateName]) {
        newData[forwardStateName] = this.xOptions.states[forwardStateName]
      } else if (!forwardStateAllowEmpty) {
        let value = forwardStateDefaultValue
        if (typeof value === "function") {
          value = value()
        }
        newData[forwardStateName] = new DataContainer(value)
      }
    }
  }

  return newData
}

function extendEvents(extraOptions: any = {}) {
  if (!this.xDisableForwardEvent) {
    if (this.options) {
      this.options.on = merge.clone(this.options.on, this.$listeners);
    }
    if (extraOptions.forwardEvents) {
      for (const eventName in extraOptions.forwardEvents) {
        const forwardEventName = extraOptions.forwardEvents[eventName]
        this.options.on[eventName] = (event) => {
          this.emit(forwardEventName, { self: this, event });
          event.stopPropagation()
        };
      }
    }
  } else {
    this.options.on = this.$listeners
  }
}

function baseInitialize(extraOptions: any = {}) {
  this.options = merge.clone({
    attrs: {},
  }, this.xOptions);

  if (!this.options.ignoreDefault) {
    if (extraOptions.defaultClass) {
      const defaultClassValue = propertyGetter.get(
        this.$vuetifyx,
        extraOptions.defaultClass,
        {}
      );
      if (defaultClassValue) {
        this.options = merge.clone(defaultClassValue, this.options);
      }
    }

    if (this.xDefaultClass) {
      const defaultClassValue = propertyGetter.get(
        this.$vuetifyx,
        this.xDefaultClass,
        {}
      );
      if (defaultClassValue) {
        this.options = merge.clone(defaultClassValue, this.options);
      }
    }
  }

  this.options.attrs = merge.clone(this.options.attrs, this.$attrs);

  if (this.options.attrs.xClass) {
    if (!extraOptions.disableInheritClass) {
      this.options.attrs.class = this.options.attrs.xClass
    }
  }
  if (this.options.attrs.extraClasses) {
    const items = []
    for (const k in this.options.attrs.extraClasses) {
      if (this.options.attrs.extraClasses[k]) {
        items.push(k)
      }
    }
    if (!this.options.attrs.class) {
      this.options.attrs.class = ""
    }
    this.options.attrs.class += " " + items.join(" ")
  }
  if (this.options.attrs.extraStyles) {
    const items = []
    for (const k in this.options.attrs.extraStyles) {
      const v = this.options.attrs.extraStyles[k]
      items.push(`${k}: ${v}`)
    }
    if (!this.options.attrs.style) {
      this.options.attrs.style = ""
    }
    this.options.attrs.style += " " + items.join("; ")
  }

  if (this.xType) {
    this.options.type = this.xType;
  }
  if (!this.options.type) {
    this.options.type = extraOptions.defaultType
  }

  extendEvents.call(this, extraOptions)

  this.emit = (name, data) => {
    const event = {
      data,
      self: this,
    }
    this.$emit(name, event);
    this.options.on[name] && this.options.on[name](event);
  }
  this.setState = (stateName, value) => {
    this.$set(this[stateName], "value", value)
  }

  this.context = () => {
    if (typeof this.xContext === "function") {
      return this.xContext()
    }
    return this.xContext
  }

  this.meta = () => {
    if (typeof this.xMeta === "function") {
      return this.xMeta()
    }
    return this.xMeta
  }

  if (this.xContainer) {
    this.xContainer.value = this
  }
  if (this.options.ext && this.options.ext.container) {
    this.options.ext.container.value = this
  }

  if (this.options.handlers && this.options.handlers.initialize) {
    this.options.handlers.initialize.call(this)
  }
}

function apply(component: any = {}) {
  if (component.extraOptions && component.extraOptions.forwardStates) {
    if (!component.props) {
      component.props = []
    }
    component.props = component.props.concat(Object.keys(component.extraOptions.forwardStates))
  }

  const newComponent: any = {
    props: extendProps(component.props),
    inheritAttrs: false,
    data() {
      return extendData.call(this, component.data, component.extraOptions)
    },
    created() {
      baseInitialize.call(this, component.extraOptions)
      this.initialize && this.initialize.call(this)
      component.created && component.created.call(this)
    },
  }
  for (const key in component) {
    if (key === "extraOptions" || newComponent[key]) {
      continue
    }
    newComponent[key] = component[key]
  }

  if (!newComponent.watch) {
    newComponent.watch = {}
  }
  newComponent.watch.$listeners = {
    handler() {
      extendEvents.call(this, component.extraOptions)
    },
    deep: true,
  }

  return Vue.extend(newComponent)
}

export default {
  apply
}
