import vOutsideEvents from "vue-outside-events"
import Fragment from 'vue-fragment'
import VueLodash from 'vue-lodash'
import vueLoadingOverlay from 'vue-loading-overlay'
import vueScrollactive from 'vue-scrollactive'
import vueInfiniteScroll from 'vue-infinite-scroll'
import VueExpandableImage from 'vue-expandable-image'
import VueReadMore from 'vue-read-more';
import VueJsonViewer from 'vue-json-viewer'
import lodash from 'lodash'
import moment from 'moment'
import humanizeDuration from "humanize-duration"
import veeValidate from "./plugins/veeValidate"
import directiveEmit from "./utils/directiveEmit"
import stateManager from "../../services/stateManager"

export default {
  install(Vue) {
    Vue.component("XArrayInput", () => import("./components/XArrayInput.vue"))
    Vue.component("XAutocomplete", () => import("./components/XAutocomplete.vue"))
    Vue.component("XBasicBtn", () => import("./components/XBasicBtn.vue"))
    Vue.component("XBreadcrumbs", () => import("./components/XBreadcrumbs.vue"))
    Vue.component("XBtn", () => import("./components/XBtn.vue"))
    Vue.component("XCardBasedForm", () => import("./components/XCardBasedForm.vue"))
    Vue.component("XConfirmDialog", () => import("./components/XConfirmDialog.vue"))
    Vue.component("XDataTable", () => import("./components/XDataTable.vue"))
    Vue.component("XDateTimePicker", () => import("./components/XDateTimePicker.vue"))
    Vue.component("XDefaultDataTable", () => import("./components/XDefaultDataTable.vue"))
    Vue.component("XDialog", () => import("./components/XDialog.vue"))
    Vue.component("XDisplay", () => import("./components/XDisplay.vue"))
    Vue.component("XEmpty", () => import("./components/XEmpty.vue"))
    Vue.component("XForm", () => import("./components/XForm.vue"))
    Vue.component("XInput", () => import("./components/XInput.vue"))
    Vue.component("XKeyValuesInput", () => import("./components/XKeyValuesInput.vue"))
    Vue.component("XMenu", () => import("./components/XMenu.vue"))
    Vue.component("XNotifyDialog", () => import("./components/XNotifyDialog.vue"))
    Vue.component("XNotifySnackBar", () => import("./components/XNotifySnackBar.vue"))
    Vue.component("XPasswordInput", () => import("./components/XPasswordInput.vue"))
    Vue.component("XScrollableTabs", () => import("./components/XScrollableTabs.vue"))
    Vue.component("XSimpleTable", () => import("./components/XSimpleTable.vue"))
    Vue.component("XTabBasedForm", () => import("./components/XTabBasedForm.vue"))
    Vue.component("XTimePicker", () => import("./components/XTimePicker.vue"))

    veeValidate.install(Vue)
    Vue.use(Fragment.Plugin)
    Vue.use(VueLodash, { name: 'custom', lodash })
    Vue.use(vOutsideEvents)
    Vue.use(vueLoadingOverlay)
    Vue.use(vueScrollactive)
    Vue.use(vueInfiniteScroll)
    Vue.use(VueExpandableImage)
    Vue.use(VueReadMore);
    Vue.use(VueJsonViewer);

    Vue.directive('XAutofocus', (el, binding) => {
      let value = true
      if (binding.value !== undefined) {
        value = binding.value
      }
      if (!value) {
        return
      }
      setTimeout(() => {
        el.focus()
        const input = el.querySelector(":not(.v-select__slot) input,textarea")
        if (input) {
          input.focus()
        }
      }, 100)
    })

    Vue.directive('height-to-bottom', (el, binding, vnode) => {
      function reposition() {
        const extra = binding.value || 0
        const offsetTop = el.getBoundingClientRect().top
        const height = window.innerHeight - offsetTop - extra
        el.style.height = `${height}px`
        directiveEmit(vnode, "heightChanged", height)
      }
      window.addEventListener("resize", () => {
        reposition();
      });
      setTimeout(reposition, 0)
    })

    function normalizeDate(value) {
      if (isNaN(value)) {
        return value
      }
      if (!value) {
        return ""
      }
      if (value < 10e10) {
        return value * 1000
      }
      return value
    }

    function dateFormat(value, format) {
      let m;
      if (!isNaN(value)) {
        const currentTimeZoneOffset = stateManager.get("currentTimeZoneOffset")
        if (!isNaN(parseInt(currentTimeZoneOffset, 10))) {
          value = value + currentTimeZoneOffset
          m = moment.utc(value)
        }
      }
      if (!m) {
        m = moment(value)
      }
      return m.format(format)
    }

    Vue.filter("dateTime", (value, format = "DD/MM/YYYY - HH:mm:ss") => {
      value = normalizeDate(value)
      if (!value) {
        return ""
      }
      return dateFormat(value, format)
    })

    Vue.filter("dateFormat", (value, format = "HH:mm:ss - DD/MM/YYYY") => {
      value = normalizeDate(value)
      if (!value) {
        return ""
      }
      return dateFormat(value, format)
    })

    Vue.filter("date", (value, format = "DD/MM/YYYY") => {
      value = normalizeDate(value)
      if (!value) {
        return ""
      }
      return dateFormat(value, format)
    })

    Vue.filter("duration", (value, options = { largest: 2, round: true }) => {
      return humanizeDuration(value, options)
    })

    Vue.filter("size", (value) => {
      if (value <= 1024) {
        return value.toString() + ' B'
      } else if (value > 1024 && value <= (1024 * 1024)) {
        return (value / 1024).toFixed(2) + ' KB'
      } else if (value > (1024 * 1024) && value <= (1024 * 1024 * 1024)) {
        return (value / (1024 * 1024)).toFixed(2) + ' MB'
      } else {
        return (value / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
      }
    })

    Vue.filter("currency", (value, currency = "USD", keepCurrencySign = false) => {
      if (!value) {
        return ""
      }
      if (isNaN(value)) {
        return value
      }

      let isNegativeNumber = false
      if (value < 0) {
        isNegativeNumber = true
        value = value * (-1)
      }
      value = Math.round(value)
      if (!value) {
        return ""
      }

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      });
      let str = formatter.format(value)
      if (!keepCurrencySign) {
        str = str.substring(1)
      }
      const items = str.split(".")
      items.pop()
      let text = items.join(".")
      if (isNegativeNumber) {
        text = `-${text}`
      }
      return text
    })

    Vue.filter("number", (value, digitAfterCommon = 2) => {
      if (!value) {
        return ""
      }
      if (isNaN(value)) {
        return value
      }
      const isNegativeNumber = value < 0
      if (isNegativeNumber) {
        value = value * (-1)
      }
      if (!isNaN(digitAfterCommon)) {
        const n = Math.pow(10, digitAfterCommon)
        value = Math.round(value * n) / n
      }
      if (isNegativeNumber) {
        value = value * (-1)
      }
      return value
    })

    Vue.filter("floor", (value) => {
      if (!value || isNaN(value)) {
        value = 0
      }
      return Math.floor(value)
    })

    Vue.filter("number2", (value, digitAfterCommon = 2) => {
      if (!value) {
        return ""
      }
      if (isNaN(value)) {
        return value
      }
      const isNegativeNumber = value < 0
      if (isNegativeNumber) {
        value = value * (-1)
      }
      if (!isNaN(digitAfterCommon)) {
        const n = Math.pow(10, digitAfterCommon)
        value = Math.round(value * n) / n
      }

      let text = Vue.filter("currency")(value)
      if (isNegativeNumber) {
        text = `-${text}`
      }
      return text
    })

    Vue.filter("number3", (value, digitAfterCommon = 2, defaultValue = "") => {
      if (!value) {
        return defaultValue
      }
      if (isNaN(value)) {
        return value
      }
      const isNegativeNumber = value < 0
      if (isNegativeNumber) {
        value = value * (-1)
      }
      let demicalPartText = ""
      if (!isNaN(digitAfterCommon)) {
        const n = Math.pow(10, digitAfterCommon)
        const demicalPart = Math.abs(value - Math.floor(value))
        value = Math.floor(value)
        let demicalPartValue = Math.round(demicalPart * n) / n
        if (demicalPartValue === 1) {
          value += 1
          demicalPartValue = 0
        }
        demicalPartText = demicalPartValue.toString().split(".")[1]
      }

      let text = Vue.filter("currency")(value) || 0
      if (demicalPartText) {
        text = `${text}.${demicalPartText}`
      }
      if (isNegativeNumber) {
        text = `-${text}`
      }
      return text
    })

    Vue.filter("percentage", (value, digitAfterCommon = 2) => {
      if (isNaN(value)) {
        return ""
      }
      if (!isNaN(digitAfterCommon)) {
        const n = Math.pow(10, digitAfterCommon)
        value = Math.round(value * n) / n
      }

      return value
    })
  }
}
