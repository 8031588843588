export default {
    attrs: {},
    content: {
        template: {
            label: {
                attrs: {
                    class: "my-1",
                    color: "primary",
                },
            }
        }
    }
}
