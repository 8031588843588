import Vue from "vue"
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    errorShown: false,
    errorMessage: "",
  },
  mutations: {
    setErrorMessage(state, message) {
      console.log(message)
      state.errorMessage = message
      state.errorShown = !!message
    }
  },
  actions: {
  },
  modules: {
  }
})
