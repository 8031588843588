






import Vue from "vue";
import { ApiClient } from "@common/services/ApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  created() {
    const self = this;
    ApiClient.setUserManager(userManager);
    ApiClient.setErrorHandler((err) => {
      if (err.statusCode === 401) {
        userManager.deleteAccessToken();
        self.$router.push("/login");
        return;
      }
      console.log(err);
      self.$store.commit("setErrorMessage", err?.response?.body?.message || err);
    });
  },
});
